<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="143.927" viewBox="0 0 180 143.927">
    <g id="exam" transform="translate(0 -51.303)">
      <g id="Group_2822" data-name="Group 2822" transform="translate(0 51.303)">
        <g id="Group_2821" data-name="Group 2821">
          <path id="Path_211" data-name="Path 211" d="M178.3,75.814l-7.774-7.774a5.824,5.824,0,0,0-8.237,0l-.321.321V63.14A11.851,11.851,0,0,0,150.128,51.3H11.837A11.851,11.851,0,0,0,0,63.14V183.393a11.851,11.851,0,0,0,11.837,11.837h138.29a11.851,11.851,0,0,0,11.837-11.837V100.384L178.3,84.052A5.832,5.832,0,0,0,178.3,75.814ZM156.328,183.393a6.208,6.208,0,0,1-6.2,6.2H11.837a6.208,6.208,0,0,1-6.2-6.2V63.14a6.208,6.208,0,0,1,6.2-6.2h138.29a6.208,6.208,0,0,1,6.2,6.2V74l-12.4,12.4V72.159a2.818,2.818,0,0,0-5.189-1.524L127.581,87.992,116.423,70.635a2.818,2.818,0,0,0-5.189,1.524v30.063a2.818,2.818,0,0,0,5.637,0V81.756l8.339,12.972a2.819,2.819,0,0,0,4.742,0l8.339-12.972v10.28L85.828,144.5H68.957a2.818,2.818,0,1,0,0,5.637H81.768L73.175,168.55H44.906a2.818,2.818,0,1,0,0,5.637H74.97a2.806,2.806,0,0,0,1.191-.265L98.709,163.4a2.823,2.823,0,0,0,.8-.561l12.7-12.7H135.1a2.818,2.818,0,0,0,0-5.637H117.849l38.479-38.478v77.372ZM87.3,151.609l7.428,7.428-13.927,6.5Zm11.719,3.748-8.039-8.039,66.4-66.4,8.04,8.04Zm75.291-75.291-4.9,4.9-8.04-8.04,4.9-4.9a.189.189,0,0,1,.266,0l7.774,7.773A.19.19,0,0,1,174.311,80.066Z" transform="translate(0 -51.303)" fill="currentColor"/>
        </g>
      </g>
      <g id="Group_2824" data-name="Group 2824" transform="translate(18.038 69.341)">
        <g id="Group_2823" data-name="Group 2823">
          <path id="Path_212" data-name="Path 212" d="M72.164,132.674H56.945v-9.395H69.158a2.818,2.818,0,0,0,0-5.637H56.945v-9.395H72.164a2.818,2.818,0,0,0,0-5.637H54.126a2.819,2.819,0,0,0-2.818,2.818v30.063a2.819,2.819,0,0,0,2.818,2.818H72.164a2.818,2.818,0,1,0,0-5.637Z" transform="translate(-51.308 -102.61)" fill="currentColor"/>
        </g>
      </g>
      <g id="Group_2826" data-name="Group 2826" transform="translate(45.094 69.341)">
        <g id="Group_2825" data-name="Group 2825">
          <path id="Path_213" data-name="Path 213" d="M157.338,133.731,146.721,120.46l10.617-13.271a2.818,2.818,0,0,0-4.4-3.521l-9.824,12.281-9.825-12.28a2.818,2.818,0,1,0-4.4,3.521L139.5,120.461l-10.617,13.271a2.818,2.818,0,0,0,4.4,3.521l9.825-12.28,9.825,12.28a2.818,2.818,0,1,0,4.4-3.521Z" transform="translate(-128.268 -102.61)" fill="currentColor"/>
        </g>
      </g>
      <g id="Group_2828" data-name="Group 2828" transform="translate(78.163 69.341)">
        <g id="Group_2827" data-name="Group 2827" transform="translate(0 0)">
          <path id="Path_214" data-name="Path 214" d="M251.965,134.941,246.677,108.5a7.347,7.347,0,0,0-7.186-5.891H234.86a7.348,7.348,0,0,0-7.186,5.891l-5.288,26.438a2.818,2.818,0,1,0,5.527,1.105l1.351-6.753h15.822l1.351,6.753a2.819,2.819,0,0,0,5.528-1.105Zm-21.573-11.285,2.81-14.048a1.7,1.7,0,0,1,1.658-1.359h4.631a1.7,1.7,0,0,1,1.658,1.359l2.81,14.048Z" transform="translate(-222.331 -102.612)" fill="currentColor"/>
        </g>
      </g>
      <g id="Group_2830" data-name="Group 2830" transform="translate(18.038 114.436)">
        <g id="Group_2829" data-name="Group 2829">
          <path id="Path_215" data-name="Path 215" d="M60.139,230.881a8.831,8.831,0,1,0,8.831,8.831A8.841,8.841,0,0,0,60.139,230.881Zm0,12.025a3.194,3.194,0,1,1,3.194-3.194A3.2,3.2,0,0,1,60.139,242.906Z" transform="translate(-51.308 -230.881)" fill="#a3a3a3"/>
        </g>
      </g>
      <g id="Group_2832" data-name="Group 2832" transform="translate(18.038 138.486)">
        <g id="Group_2831" data-name="Group 2831">
          <path id="Path_216" data-name="Path 216" d="M60.139,299.291a8.831,8.831,0,1,0,8.831,8.831A8.841,8.841,0,0,0,60.139,299.291Zm0,12.025a3.194,3.194,0,1,1,3.194-3.194A3.2,3.2,0,0,1,60.139,311.316Z" transform="translate(-51.308 -299.291)" fill="#a3a3a3"/>
        </g>
      </g>
      <g id="Group_2834" data-name="Group 2834" transform="translate(18.038 162.537)">
        <g id="Group_2833" data-name="Group 2833">
          <path id="Path_217" data-name="Path 217" d="M60.139,367.7a8.831,8.831,0,1,0,8.831,8.831A8.841,8.841,0,0,0,60.139,367.7Zm0,12.025a3.194,3.194,0,1,1,3.194-3.194A3.2,3.2,0,0,1,60.139,379.726Z" transform="translate(-51.308 -367.701)" fill="#a3a3a3"/>
        </g>
      </g>
      <g id="Group_2836" data-name="Group 2836" transform="translate(42.088 120.448)">
        <g id="Group_2835" data-name="Group 2835">
          <path id="Path_218" data-name="Path 218" d="M170.638,247.983h-48.1a2.818,2.818,0,1,0,0,5.637h48.1a2.818,2.818,0,1,0,0-5.637Z" transform="translate(-119.718 -247.983)" fill="#a3a3a3"/>
        </g>
      </g>
      <g id="Group_2838" data-name="Group 2838" transform="translate(42.088 144.499)">
        <g id="Group_2837" data-name="Group 2837">
          <path id="Path_219" data-name="Path 219" d="M134.562,316.394H122.536a2.818,2.818,0,1,0,0,5.637h12.025a2.818,2.818,0,1,0,0-5.637Z" transform="translate(-119.718 -316.394)" fill="#a3a3a3"/>
        </g>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "svg-exam"
}
</script>

<style scoped>

</style>