<template>
  <div>
    <h1 class="text-4xl text-center py-10">My exams</h1>


    <exam-alert v-model:exam="selectedExam"></exam-alert>

    <div class="relative w-full max-w-6xl mx-auto my-3">
      <div class="w-full my-3">
        <div class="my-3 relative" style="min-height: 400px">

          <div class=" flex flex-col md:flex-row gap-4 items-center justify-between">
            <pagination v-model="page" :links="paginationLinks"></pagination>

            <btn variant="success" @click.prevent="loadExams">Reload</btn>
          </div>

          <overly class="rounded" background="bg-blue-100" v-if="loading">
            <spinner class="text-red-600 h-10 w-10"></spinner>
            Loading...
          </overly>
          <div class="grid md:grid-cols-3 gap-4 py-4 w-full">
            <exam-item v-for="exam in exams" :exam="exam" :key="exam.id" @click-enter="enter_exam"></exam-item>
          </div>

          <pagination v-model="page" :links="paginationLinks" navigation-type="page-link"></pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ExamItem from "./components/exam-item";
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
import Btn from "../../../components/btn";
import ExamAlert from "./components/exam-alert";
import examAlertMixins from "./mixins/exam-alert-mixins";
import Pagination from "../../../components/pagination";

export default {
  name: "index",
  components: {Pagination, ExamAlert, Btn, Spinner, Overly, ExamItem},
  mixins:[ examAlertMixins ],
  data(){

    return {
      exams: [],
      paginationLinks:[],
      loading: true,
    }

  },
  computed:{
    programId(){
      return this.$route.params.program_id;
    },
    page: {
      set( page ){
        this.$extendRouteQueryParams({page});
      },
      get(){
        return this.$route.query.page || 1;
      }
    }
  },
  methods:{
    loadExams( ){
      const params = {};
      if( this.programId ) {
        params.program_id = this.programId;
      }

      if( this.page) {
        params.page = this.page;
      }

      this.loading = true;

      this.$store.dispatch( 'exam/list', {
        params,
        success: ({data}) => {
          this.exams = data.exams;
          this.paginationLinks = (data.meta || {}).links || [];
        },
        error:(response) => {

        }

      }).finally( () => this.loading = false )
    }
  },
  created() {
    this.loadExams();
  },
  watch:{
    programId( id ){
      if( id ) {
        this.loadExams( );
      }
    },
    page(){
      this.loading = true;
      this.loadExams()
    }
  }

};
</script>

<style scoped>
  .instruction-list  {
    text-align: justify;
  }

  .instruction-list ol {
     @apply mb-3
  }
</style>
