<template>
    <ul>
        <li class="mx-px inline-block" v-for="link in links">
            <pagination-link
                :is="navigationTagName"
                :disabled="disabled"
                :page-number="link.page"
                :current-page="modelValue"
                @page-click="navigate"
                @focus="$emit('focus', $event)"
                @blur="$emit('blur', $event)"
                class="cursor-pointer border px-2 py-1  inline-block bg-white rounded shadow-sm"
            >
                <slot v-bind="link">
                  <span v-html="link.label"></span>
                </slot>
            </pagination-link>
        </li>
    </ul>
</template>

<script>
import PaginationLink from "./pagination-link";

export default {
    name: "pagination",
    components: {PaginationLink},
    props:[ 'modelValue', 'links', 'disabled', 'navigationType','pageName'  ],
    created( ) {
        this.$emit( 'update:modelValue', ((this.$route || {}).query || {}).page || 1 );
    },
    computed: {
        navigationTagName( ){
            switch (this.navigationType) {
                case 'page-link':
                    return 'a';
                case 'button':
                    return 'button';
            }
        },
        links(){
            let links = this.links;


            if( Array.isArray( links ) ) {

                links = links.map( ( item, index ) => {
                    const pName= this.pageName || 'page';
                    try {

                        item.page = Number((new URL( item.url ))
                            .searchParams
                            .get( pName ) || index + 1
                        );

                        return item;
                    } catch (e) {
                        item.page = null;
                    }

                    return item;
                })

            }

            return links;
        }
    },

    methods: {
        navigate( pageNumber ){
            this.$emit('update:modelValue', pageNumber );
        },
    }

}
</script>

<style scoped>

</style>
