<template>

  <div class="p-4 border rounded-sm shadow  flex flex-col justify-between">
<!--    <img-->
<!--        class="w-full h-auto"-->
<!--        src="../../../../assets/batch.jpg"-->
<!--        alt="{{ exam.batchName }}"-->
<!--    />-->

    <div class="py-4 text-green-600 hover:text-green-400">

      <svg-exam class="w-full text-center mb-3"></svg-exam>

      <div class="text-gray-600 hover:text-gray-800">

        <h3 class="font-bold text-xl text-center">{{ exam.name }}</h3>

        <div v-if="programDetails" class="text-gray-500">
          <h3 class="font-bold text-sm mt-4 text-center">Batch : {{ exam.programDisplayName }}</h3>
          <p class="font-semibold text-sm text-center mt-2">Course : {{ exam.courseName }} {{ exam.batchYear }}</p>
          <h4 class="font-semibold text-sm text-center mt-1">Branch : {{ exam.branchName }} ({{exam.servicePointName}})</h4>
        </div>
      </div>
    </div>

<!--     {{ exam }}-->

    <div class="flex justify-center flex-col items-center border-t border-dashed border-gray-300 pt-2">
      <div class="mb-2 text-orange-700 bg-blue-100 px-5 py-2 border border-red-100 rounded w-full">

        <div class="mb-3 text-center font-semibold italic h-10">{{ exam.activeMessage }}</div>

        <div class="text-xs flex justify-between mb-2 text-blue-600">
          <div class="font-semibold ">Entry Time:</div> <div>{{ exam.starting }}</div>
        </div>
        <div class="text-xs flex mb-2 justify-between text-blue-600">
          <div class="font-semibold">Last Entry:</div>
          <div :class="{'italic font-normal text-blue-400': !exam.lastEntryTime}">{{ exam.lastEntryTime || 'no time limit' }}</div>
        </div>
        <div class="text-xs flex justify-between text-blue-600">
          <div class="font-semibold" >Result Publish:</div>
          <div :class="{'italic font-normal text-blue-400':!exam.resultPublishAt}">{{ exam.resultPublishAt || 'after exam finish' }}</div>
        </div>
      </div>

      <div class="flex justify-center items-center ">


        <btn
            class="mx-2"
            :loading="examStarting"
            @click="$emit('clickEnter', {exam})"
            variant="info"
            :disabled="!(exam.examIsActive && exam.participantIsActive)"
            v-if="examBtnVisible"
        >
          Enter Exam
        </btn>

        <btn :router-path="'/my/exams/'+exam.examId+'/result/'+ exam.participantId + '/answers'"
             class="mx-2"
             variant="success-outline"
             :disabled="!(exam.answerIsActive && exam.participantIsActive)"
             v-if="!exam.resultIsActive && !exam.examIsActive && this.exam.isStarted && this.exam"
        >View Answeres</btn>

        <btn :router-path="'/my/exams/'+exam.examId+'/result/'+ exam.participantId"
             class="mx-2"
             variant="success-outline"
             :disabled="!(exam.resultIsActive && exam.participantIsActive)"
             v-if="!exam.examIsActive && !exam.answerIsActive && this.exam.isStarted"
        >View Result</btn>

<!--        <div v-else-if="exam.participatedStatus && !exam.resultIsPublished">-->
<!--          Result will be published at {{ exam.resultPublishAt }}-->
<!--        </div>-->



      </div>
<!--      <div class="px-4 py-2 border rounded bg-gray-200">-->
<!--        Amount: {{ exam.amount }}-->
<!--      </div>-->

    </div>
  </div>


</template>

<script>
import Btn from "../../../../components/btn";
import SvgExam from "../../../../components/svgs/svg-exam";
export default {
  emits:['clickEnter'],
  name: "exam-item",
  components: {SvgExam, Btn},
  props: { exam: Object, programDetails: { type: Boolean,  default: true }},
  data() {
    return {
      examStarting: false
    }
  },
  computed:{
    examBtnVisible(){

      if( !this.exam.isStarted ) {
        return true;
      }

      return this.exam.examIsActive && !this.exam.resultIsActive && !this.exam.answerIsActive;

      // if( this.exam.examIsActive && !this.exam.resultIsActive && !this.exam.answerIsActive ){
      //   return true;
      // }

      // return !this.exam.resultIsActive && !this.exam.answerIsActive;

    },
    examDisabled(){
      return !this.exam.participantIsActive
    }
  },
  methods: {
    participateInExam() {
      this.examStarting = true;
      this.$store.dispatch( 'exam/participate', {
        data: {
          exam_id: this.exam.examId,
          participant_id: this.exam.participantId
        },
        success: ({data}) => {
          const participantExam = data.participantExam || {};
          this.$router.push( '/my/exams/'+this.exam.examId+'/participate/'+participantExam.id)
        }
      }).finally( r => this.examStarting = false );

    }
  }

}
</script>

<style scoped>

</style>