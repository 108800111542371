<template>
    <component :is="is" v-bind="attrs" @click.prevent="navigate"
       :class="{'bg-orange-600 text-white': currentPage && pageNumber == currentPage}"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    emits: ['pageClick'],
    name: "pagination-link",
    props: ['pageNumber', 'currentPage', 'is'],
    computed: {
        component(){
            if(this.is) {
                return this.is;
            }

            return 'a';
        },
        attrs(){
            const attrs = {};
            if( this.component === 'a') {
                attrs.href = this.href;
            }


            return {...attrs, ...this.$attrs};
        },
        href( ){
            const fullPath = this.$route.fullPath;
            const queryMatch = fullPath.match(/\?(.*)/);

            if( queryMatch ) {
                if( (queryMatch[1] || '').match(/=/) ) {
                    return `${fullPath}&page=${this.pageNumber}`;
                } else {

                    return `${fullPath}${(queryMatch[1] || '').length>0?'&':''}page=${this.pageNumber}`;
                }
            }else {
                return `${fullPath}?page=${this.pageNumber}`;
            }
        }
    },
    methods: {
        navigate(){
            if( this.pageNumber && this.component === 'a' ) {
                this.$extendRouteQueryParams({page:this.pageNumber});
            }
            this.$emit( "pageClick", this.pageNumber );
        }
    }
}
</script>

<style scoped>

</style>
