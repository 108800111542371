export default {
    data(){
        return {
            selectedExam:null
        }
    },
    methods: {
        enter_exam({exam}){
            this.selectedExam = exam;
        },
    }
}