<template>
  <overly position="fixed" v-if="currentExam">
    <div class="static top-0">
      <div class="max-w-lg bg-white">
        <div class="block px-6 ">
          <div class="border-b py-2 font-semibold text-lg text-center">
            Exam Instruction
          </div>
          <div class="py-2 overflow-y-auto" style="max-height: 350px;">

            <div class="text-gray-600 hover:text-gray-800">

              <h3 class="font-bold text-base text-center">{{ currentExam.name }}</h3>

              <div  class="text-gray-500">
                <h3 class="font-bold text-sm mt-4 text-center">Batch : {{ currentExam.programDisplayName }}</h3>
                <p class="font-semibold text-sm text-center mt-1">Course : {{ currentExam.courseName }} {{ currentExam.batchYear }}</p>
                <h4 class="font-semibold text-sm text-center mt-1">Branch : {{ currentExam.branchName }} ({{currentExam.servicePointName}})</h4>
              </div>
            </div>

            <ul class="instruction-list px-2">
              <ol class="font-semibold text-lg text-center mt-6">নিম্নলিখিত নিয়মাবলী অনুসরন করুন</ol>


              <ol>1. অনুগ্রহ করে "গুগল ক্রোম ব্রাউজার" ব্যবহার করুন।</ol>

              <ol>2. "নিরাপদ ওয়েবসাইট লিঙ্ক" https://mymretina.com/ দিয়ে লগইন করুন।</ol>

              <ol>3. নিরবচ্ছিন্ন ইন্টারনেট সংযোগ রাখুন (মোবাইল ডেটা নেটওয়ার্ক হলে ভালো হয় তবে ওয়াইফাই কানেকশন হলেও চলবে)।</ol>

              <ol>4. পরীক্ষার সময় ডিভাইস(মোবাইল/ল্যাপটপ/ডেস্কটপ) এ কোন কল রিসিভ করা যাবে না।</ol>

              <ol>5. কোনো বিরতি ছাড়াই পরীক্ষা সম্পূর্ণ করুন।</ol>

              <ol>6."স্ক্রিন বন্ধ" এবং "ব্রাউজার বন্ধ" পরীক্ষার যে কোনো মুহূর্তে অনুমোদিত নয়। "স্ক্রিন বন্ধ" এবং "ব্রাউজার বন্ধ" করা হলে পরীক্ষাটি অটোমেটিক্যালি সাবমিট হয়ে যাবে।</ol>

              <ol>7. নির্দিষ্ট পরীক্ষার জন্য "সময়কাল" এর পরে পরীক্ষা স্বয়ংক্রিয়ভাবে সাবমিট হয়ে যাবে।</ol>

            </ul>

          </div>
          <div class="border-b py-8 pb-8 px-1 font-semibold text-xl text-center">
            <btn @click="participateInExam" variant="success" size="md" class="mx-4">Start Exam <span class="hidden sm:inline">Now</span></btn>
            <btn @click="currentExam=null;$emit('update:exam', null);" variant="danger-outline" size="md"  class="mx-4">Cancel</btn>
          </div>
        </div>
      </div>
    </div>
  </overly>
</template>

<script>
import Overly from "../../../../components/overly";
export default {
  name: "exam-alert",
  components: {Overly},
  emits:['update:exam'],
  props:['exam'],
  data(){
    return {
      currentExam: null
    }
  },
  methods: {
    participateInExam() {
      this.examStarting = true;
      this.$store.dispatch( 'exam/participate', {
        data: {
          exam_id: this.currentExam.examId,
          participant_id: this.currentExam.participantId
        },
        success: ({data}) => {
          const participantExam = data.participantExam || {};
          this.$router.push( '/my/exams/'+this.currentExam.examId+'/participate/'+participantExam.id)
        }
      }).finally( r => this.currentExamStarting = false );

    }
  },
  watch: {
    exam( e ){
      this.currentExam = e;
      this.$emit( 'update:exam', e);
    }
  }
}
</script>

<style scoped>

</style>